<template>
  <div class="container" v-if="$route.meta && $route.meta.showTabbar">
    <div class="current-van-tabbar__placeholder"></div>
    <van-tabbar
      v-model:active="active"
      :fixed="true"
      :placeholder="false"
      active-color="#006AFA"
      inactive-color="#999999"
      :border="false"
      @change="onChange"
    >
      <!-- name是路由的name, to是跳转的地址 -->
      <van-tabbar-item name="index">
        <template #icon="props">
          <img
            class="tabbar-icon"
            :src="
              props.active
                ? require('@/assets/images/tabbar/home1.png')
                : require('@/assets/images/tabbar/home2.png')
            "
          />
        </template>
        <div class="tabbar-text">首页</div>
      </van-tabbar-item>
      <van-tabbar-item name="customization">
        <template #icon="props">
          <img
            class="tabbar-icon"
            :src="
              props.active
                ? require('@/assets/images/icon/customize-active.png')
                : require('@/assets/images/icon/customize.png')
            "
          />
        </template>
        <div class="tabbar-text">定制</div>
      </van-tabbar-item>
      <van-tabbar-item name="chat">
        <template #icon="props">
          <van-badge
            v-if="unread_count > 0"
            :content="unread_count"
            :max="99"
            class="badge"
          >
            <img
              class="tabbar-icon"
              :src="
                props.active
                  ? require('@/assets/images/tabbar/chat1.png')
                  : require('@/assets/images/tabbar/chat2.png')
              "
            />
          </van-badge>
          <img
            v-else
            class="tabbar-icon"
            :src="
              props.active
                ? require('@/assets/images/tabbar/chat1.png')
                : require('@/assets/images/tabbar/chat2.png')
            "
          />
        </template>
        <div class="tabbar-text">消息</div>
      </van-tabbar-item>
      <van-tabbar-item name="procurement">
        <template #icon="props">
          <img
            class="tabbar-icon"
            :src="
              props.active
                ? require('@/assets/images/icon/purchase_list_active.png')
                : require('@/assets/images/icon/purchase_list.png')
            "
          />
        </template>
        <div class="tabbar-text">采购清单</div>
      </van-tabbar-item>
      <van-tabbar-item name="my">
        <template #icon="props">
          <img
            class="tabbar-icon"
            :src="
              props.active
                ? require('@/assets/images/tabbar/my1.png')
                : require('@/assets/images/tabbar/my2.png')
            "
          />
        </template>
        <div class="tabbar-text">我的</div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { sign } from "@/api/im";
import { TIM } from "@/lib/tim/tim.js";
import { mapGetters } from "vuex";
import { isApp } from '@/lib/utils';

export default {
  components: {},
  data() {
    return {
      unread_count: 0,
      lastGetListTime: 0,
      active: "index",
      activeArr: ["index", "customization", "chat", "procurement", "my"],
    };
  },
  computed: {
    ...mapGetters({
      getTimReady: "chat/getTimReady",
    }),
  },
  beforeDestroy() {
    let that = this;
    this.$tim.off(TIM.EVENT.SDK_READY, that.noReadyFun);
    this.$tim.off(TIM.EVENT.SDK_READY, that.readyFun);
    this.$tim.off(
      TIM.EVENT.CONVERSATION_LIST_UPDATED,
      that.getConversationList
    );
  },
  mounted() {
    this.$nextTick(() => {
      if (this.activeArr.indexOf(this.$route.name) > -1) {
        this.active = this.$route.name;
      }
      setTimeout(() => {
      this.genTestUserSig();
      }, 200);
    });
  },
  methods: {
    onChange(e) {
      let path;
      switch (e) {
        case "index":
          path = "/";
          this.active = "index";
          break;
        case "customization":
          path = "/customization";
          this.active = "customization";
          break;
        case "my":
          path = "/my";
          this.active = "my";
          break;
        case "procurement":
          path = "/procurement";
          this.active = "procurement";
          break;
        case "chat":
          path = "/chat";
          this.active = "chat";
          break;
      }
      this.$router.push({ path: path + (isApp() ? '' : "?_replace=true") });
    },
    genTestUserSig() {
      //后期由后台生成
      // todo 后端接口获取tim用户信息，去登录
      // let SDKAPPID = 1400534813;
      // let EXPIRETIME = 604800;
      // let SECRETKEY = 'c7a37b0ffc24df6f7fdaee374435a12a0f7b7e10153157b15e54152ab07a29f7';
      // let generator = new LibGenerateTestUserSig(SDKAPPID, SECRETKEY, EXPIRETIME);
      // let userID = 'user0';
      // this.$store.dispatch('chat/doChatId', userID);
      // let userSig = generator.genTestUserSig(userID);
      sign().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.loadData(res.data.user_id, res.data.sig);
          }
        }
      });
    },
    loadData(userID, userSig) {
      let that = this;
      this.$tim
        .login({
          userID: userID,
          userSig: userSig,
        })
        .then((res) => {
          if (res.code == 0) {
            this.$store.commit("chat/setChatId", userID);
            this.$tim.on(TIM.EVENT.SDK_READY, that.readyFun);
            this.$tim.on(
              TIM.EVENT.CONVERSATION_LIST_UPDATED,
              that.getConversationList
            );
          }
          if (res.data.repeatLogin === true) {
          }
        })
        .catch((err) => {});
    },
    getConversationList() {
      if (this.getTimReady) {
        let currTime = new Date().getTime();
        if (currTime - this.lastGetListTime >= 1000) {
          this.lastGetListTime = currTime;
          this.$tim.getConversationList().then((res) => {
            if (res.code == 0) {
              let list = res.data.conversationList;
              this.unread_count = 0;
              list.forEach((item) => {
                if (
                  item.conversationID != "C2C2ad9b4b89816725f35a389a451713daf"
                ) {
                  this.unread_count += item.unreadCount;
                }
              });
            }
          });
        }
      }
    },
    readyFun(event) {
      this.setTimeReady(true);
    },
    noReadyFun(event) {
      // console.log(event);
      this.setTimeReady(false);
    },
    setTimeReady(v) {
      this.$store.commit("chat/setTimReady", v);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.active = to.name;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  :deep(.van-tabbar) {
    height: 98px;
  }

  .current-van-tabbar__placeholder {
    @include safe-area-inset-bottom-height(98px);
  }

  :deep(.van-tabbar-item__icon) {
    margin-bottom: 0px;
  }
}

.tabbar-icon {
  width: 56px;
  height: 56px;
}

.tabbar-text {
  font-size: 22px;
  font-weight: 500;
}

.badge {
  width: 56px;
  height: 56px;

  :deep(.van-badge--fixed) {
    top: 10px;
    right: 8px;
  }
}
</style>
